@font-face {
  font-family: "GalanoGrotesque";
  src: url("./GalanoGrotesqueLight.otf");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "GalanoGrotesque";
  src: url("./GalanoGrotesqueMedium.otf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "GalanoGrotesque";
  src: url("./GalanoGrotesqueSemiBold.otf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "GalanoGrotesque";
  src: url("./GalanoGrotesqueBold.otf");
  font-weight: 700;
  font-display: swap;
}
