.h1 {
  font-size: var(--booking-module-title-h1-font-size);
  line-height: var(--booking-module-title-h1-line-height);
  font-weight: var(--booking-module-title-h1-font-weight);
  margin-top: var(--booking-module-title-h1-margin-top);
  margin-bottom: var(--booking-module-title-h1-margin-bottom);
  font-family: var(--booking-module-title-h1-font-family);
  color: var(--booking-module-title-primary);
}
.h5 {
  font-size: var(--booking-module-title-h5-font-size);
  line-height: var(--booking-module-title-h5-line-height);
  font-weight: var(--booking-module-title-h5-font-weight);
  margin-top: var(--booking-module-title-h5-margin-top);
  margin-bottom: var(--booking-module-title-h5-margin-bottom);
  font-family: var(--booking-module-title-h5-font-family);
  color: var(--booking-module-title-primary);
}
.h6 {
  font-size: var(--booking-module-title-h6-font-size);
  line-height: var(--booking-module-title-h6-line-height);
  font-weight: var(--booking-module-title-h6-font-weight);
  margin-top: var(--booking-module-title-h6-margin-top);
  margin-bottom: var(--booking-module-title-h6-margin-bottom);
  font-family: var(--booking-module-title-h6-font-family);
  color: var(--booking-module-title-primary);
}
