:root {
  --booking-module-header-padding-y: 16px;
  --booking-module-header-padding-x: 0;
  --booking-module-header-bg: var(--booking-module-white);
}

@media (max-width: 992px) {
  :root {
    --booking-module-header-padding-y: 24px;
    --booking-module-header-padding-x: 0;
  }
}
