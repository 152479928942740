:root{

    /* day view default */
    --booking-module-day-view-padding-y: 8px;
    --booking-module-day-view-padding-x: 32px;
    --booking-module-day-view-border-radius:var(--booking-module-br-xl);
    --booking-module-day-view-bg: var(--booking-module-neutral-lighten-t6);
    --booking-module-day-view-font-weight: var(--booking-module-fw-bold);
    --booking-module-day-view-font-family: var(--booking-module-body-font-family);
    --booking-module-day-view-font-size: var(--booking-module-body-fs);
    --booking-module-day-view-line-height: var(--booking-module-body-lh);
    --booking-module-day-view-color:var(--booking-module-neutral);
    --booking-module-day-view-color-lighten-t1: var(--booking-module-neutral-lighten-t1);
    --booking-module-day-view-color-lighten-t5: var(--booking-module-neutral-lighten-t5);


    /* day view primary */

    --booking-module-day-view-primary-font-size:var(--booking-module-h4-fs);
    --booking-module-day-view-primary-line-height: var(--booking-module-h4-lh);
    --booking-module-day-view-white:var(--booking-module-white);
    --booking-module-day-view-primary-bg:var(--booking-module-accent);
    --booking-module-day-view-primary-hover-bg:var(--booking-module-accent-lighten-t1);

}
@media (hover:none){
    :root {
        --booking-module-day-view-primary-hover-bg: var(--booking-module-day-view-primary-bg);
    }
}
@media screen and (max-width: 992px) {
    :root {
    --booking-module-day-view-padding-x: 0px;
    --booking-module-day-view-border-radius:var(--booking-module-br-lg);
    }
}
