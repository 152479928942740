@import url('./vars/index.css');
@import url('./fonts/index.css');
@import url('./grid/bootstap-grid.css');
@import url('./elements/index.css');


* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  position: relative;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root .container-sm {
  flex:1;
  overflow-y: auto;
  max-height: 100vh;
}

#root {
  overflow:hidden;
  height: inherit;
  display:flex;
  flex-direction: column;
}
