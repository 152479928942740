.body {
  font-size: var(--booking-module-text-body-font-size);
  line-height: var(--booking-module-text-body-line-height);
  font-weight: var(--booking-module-text-body-font-weight);
  margin-top: var(--booking-module-text-body-margin-top);
  margin-bottom: var(--booking-module-text-body-margin-bottom);
  font-family: var(--booking-module-text-body-font-family);
  color: var(--booking-module-text-primary);
}
.body2 {
  font-size: var(--booking-module-text-body2-font-size);
  line-height: var(--booking-module-text-body2-line-height);
  font-weight: var(--booking-module-text-body-font-weight);
  margin-top: var(--booking-module-text-body2-margin-top);
  margin-bottom: var(--booking-module-text-body2-margin-bottom);
  font-family: var(--booking-module-text-body-font-family);
  color: var(--booking-module-text-primary);
}
