@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spin {
    transition: all 0.8s ease;
    animation: spin 0.8s infinite linear;
    display: flex;
}
