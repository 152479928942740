
.booking-message{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 10000;
    width: 100%;
    pointer-events: none;
}
.booking-message .booking-message-notice{
    padding: 8px;
    text-align: center;
    min-height:0;}
.booking-message .booking-message-notice-content{
    display: inline-block;
    padding: 10px 16px;
    background: var(--booking-module-notification-background);
    border-radius: 20px;
    box-shadow: var(--booking-module-notification-box-shadow);
    pointer-events: all;
}

.booking-message .anticon{
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
}

.booking-message .booking-message-notice.booking-message-content-move-up-appear {
    animation-name: MessageMoveIn;
    animation-duration: 0.3s;
}
.booking-message .booking-message-notice.booking-message-content-move-up-leave{
    animation-name: MessageMoveIn;
    animation-direction: reverse;
    animation-duration: 0.2s;
}

.booking-message .booking-message-error .anticon{
    color: var(--booking-module-notification-error-color);
}

.booking-message .booking-message-success .anticon{
    color: var(--booking-module-notification-success-color);
}

@keyframes MessageMoveIn {
    100% {
        max-height: 150px;
        padding: 8px;
        opacity: 1;
    }

    0% {
        max-height: 0;
        padding: 0;
        opacity: 0;
    }
}
