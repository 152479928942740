:root {
  --booking-module-navigation-dot-width: 8px;
  --booking-module-navigation-dot-width-active: 32px;
  --booking-module-navigation-dot-height: 8px;

  --booking-module-navigation-dot-border-radius: 50%;
  --booking-module-navigation-dot-border-radius-active: 8px;

  --booking-module-navigation-dot-active-bg: var(--booking-module-bg-neutral);
  --booking-module-navigation-dot-bg: var(--booking-module-gray-lighten2);
}
