:root {
  /* Segment */
  --booking-module-segment-padding: 4px;
  --booking-module-segment-bg-color: var(
          --booking-module-neutral-lighten-t6
  );
  --booking-module-segment-border-radius: 100px;


  /* Segment - Item */
  --booking-module-segment-item-gap: 8px;
  --booking-module-segment-item-padding: 12px 24px;

  /* Colors */
  --booking-module-segment-item-color: var(--booking-module-neutral-lighten-t2);
  --booking-module-segment-item-selected-color: var(--booking-module-neutral);

  /* Typography */
  --booking-module-segment-item-fs: var(--booking-module-body-fs);
  --booking-module-segment-item-lh: var(--booking-module-body-lh);

  --booking-module-segment-item-font-family: var(
    --booking-module-body-font-family
  );
  --booking-module-segment-item-fw-selected: var(--booking-module-fw-bold);

  /* Box shadow*/
  --booking-module-segment-box-shadow: var(--booking-module-shadow-2);

}
@media screen and (max-width: 992px) {
  :root {
    --booking-module-segment-item-padding: 8px 16px;
    --booking-module-segment-item-fs: var(--booking-module-body2-fs);
    --booking-module-segment-item-lh: var(--booking-module-body2-lh);
  }
}
