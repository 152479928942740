:root {
    /* gradient default */
    --booking-module-day-select-gradient-width: 10%;
    --booking-module-day-select-gradient-height: 100%;
    --booking-module-day-select-gradient-left-bg: linear-gradient(90deg, var(--booking-module-white) 0%, rgba(255, 255, 255, 0) 100%);
    --booking-module-day-select-gradient-right-bg: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--booking-module-white) 100%);
}

@media screen and (max-width: 568px) {
    :root {
        --booking-module-day-select-gradient-width: 0%;
        --booking-module-day-select-gradient-height: 0%;
    }
}
