:root {
  /* BUTTON DEFAULT */
  --booking-module-btn-default-padding-y: 28px;
  --booking-module-btn-default-padding-x: 48px;
  --booking-module-btn-font-family: var(--booking-module-body-font-family);
  --booking-module-btn-default-font-size: var(--booking-module-body2-fs);
  --booking-module-btn-default-font-weight: var(--booking-module-fw-bold);
  --booking-module-btn-default-line-height: var(--booking-module-body2-lh);
  --booking-module-btn-default-bg: var(--booking-module-gray-lighten3);
  --booking-module-btn-default-color: var(--booking-module-neutral-lighten-t6);
  --booking-module-btn-default-border-radius: calc(
    var(--booking-module-btn-default-padding-y) * 2 +
      var(--booking-module-btn-default-line-height) / 2
  );

  --booking-module-btn-disabled-bg:var(--booking-module-gray-lighten3);
  --booking-module-btn-disabled-color:var(--booking-module-btn-default-color);

  /* BUTTON PRIMARY */
  --booking-module-btn-primary-color: var(--booking-module-white);
  --booking-module-btn-primary-bg: var(--booking-module-neutral);

  /* BUTTON WHITE */
  --booking-module-btn-white-color: var(--booking-module-neutral-lighten-t5);
  --booking-module-btn-white-bg: var(--booking-module-white);
  --booking-module-btn-box-shadow: var(--booking-module-shadow-1);

  /* BUTTON GHOST */
  --booking-module-btn-ghost-color: var(--booking-module-neutral);
  --booking-module-btn-ghost-bg: transparent;


  /* BUTTON LINK */
  --booking-module-btn-link-color: var(
          --booking-module-typography-color-accent
  );
  --booking-module-btn-link-font-weight: var(--booking-module-fw-normal);
  --booking-module-btn-link-hover-color: var(
          --booking-module-accent-lighten-t1
  );
  --booking-module-btn-link-active-color: var(
          --booking-module-typography-color-accent
  );

  /* BUTTON CIRCLE */
  --booking-module-btn-circle-border-radius: 50%;
  --booking-module-btn-circle-padding-y: 16px;
  --booking-module-btn-circle-padding-x: 16px;

  /* BUTTON WITH ICON */
  --booking-module-btn-icon-width: 48px;
  --booking-module-btn-icon-height: 48px;
}
@media screen and (max-width: 992px) {
  :root {
    --booking-module-btn-default-font-weight: var(--booking-module-fw-medium);

    /* BUTTON DEFAULT */
    --booking-module-btn-default-padding-y: 20px;
    --booking-module-btn-default-padding-x: 32px;

    /* BUTTON WITH ICON */
    --booking-module-btn-icon-width: 32px;
    --booking-module-btn-icon-height: 32px;
  }
}
