/* Arrows */
.slick-prev,
.slick-next {
  display: none !important;

  outline: none;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  display: none;
}


.slick-list {
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-initialized .slick-slide {
  display: block;
}

