.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modalContent {
  background-color: #fff;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 32px;
  border-radius: 32px;
  width: 50%;
  position: relative;
}

@media (max-width: 768px) {
  .modalContent {
    width: 96%;
    padding: 32px 16px;
  }
}

.close {
  position: absolute;
  right: 24px;
  top: 16px;
  color: #000;
  float: right;
  font-size: 28px;
  cursor: pointer;
}
