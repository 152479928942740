:root {
  /* Radio Button default  */
  --booking-module-radio-btn-font-family: var(
    --booking-module-body-font-family
  );
  --booking-module-radio-btn-default-font-size: var(--booking-module-body-fs);
  --booking-module-radio-btn-default-color-hover: var(
    --booking-module-accent-lighten-t1
  );
  --booking-module-radio-btn-default-line-height: var(--booking-module-body-lh);
  --booking-module-radio-btn-default-font-weight: var(
    --booking-module-fw-medium
  );
  --booking-module-radio-btn-default-color: var(--booking-module-neutral);
  --booking-module-radio-btn-default-bg: var(
    --booking-module-neutral-lighten-t6
  );
  --booking-module-radio-btn-default-border-radius: 32px;
  --booking-module-radio-btn-default-padding-y: 18px;
  --booking-module-radio-btn-default-padding-x: 32px;

  /* Radio Button primary */

  --booking-module-radio-btn-primary-font-weight: var(--booking-module-fw-bold);
  --booking-module-radio-btn-primary-color: var(--booking-module-white);
  --booking-module-radio-btn-primary-bg: var(--booking-module-accent);

  /* timeslot width  */
  --booking-module-time-slot-select-max-width: 600px;
}
@media (hover:none){
  :root {
    --booking-module-radio-btn-default-color-hover: var(--booking-module-radio-btn-primary-bg);
  }
}
@media screen and (max-width: 992px) {
  :root {
    /* paddings */
    --booking-module-radio-btn-default-padding-y: 16px;
    --booking-module-radio-btn-default-padding-x: 28px;
  }
}
