.screen-slider-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.screen-slider-wrapper .screen-slider-header {
  margin-bottom: 16px;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 992px) {
  .screen-slider-wrapper .screen-slider-header {
    padding: 0 0 0 0.75rem;
    text-align: left;
  }
}



.screen-slider-wrapper .screen-slider-content {
  display:flex;

  flex-direction: column;
  overflow: hidden;
  flex:1;
}

.screen-slider-wrapper .screen-slider-content .slick-slider,
.screen-slider-wrapper .screen-slider-content .slick-slider .slick-track,
.slick-slider .slick-list, .screen-slider-wrapper .screen-slider-content .slick-slider .slick-list .slick-slide > div {
height:100%;
}
.slick-slider .slick-list, .screen-slider-wrapper .screen-slider-content .slick-slider .slick-list .slick-slide > div {
  display: flex;
  flex-direction: column;
}

/*styles for screen slider, but not for inner sliders*/
.screen-slider-wrapper .screen-slider-content .slick-slider .slick-list .slick-slide:not(.screen-slider-wrapper .screen-slider-content .slick-slider .slick-list .slick-slide *) {
  overflow-y: auto;
  overflow-x: hidden;
}

/*styles for style select*/
.style-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.style-screen .slick-slider .slick-list .slick-slide {
  overflow-y: clip;
  overflow-x: visible;
}


.screen-slider-wrapper .screen-slider-content .previous-slide-button,
.screen-slider-wrapper .screen-slider-content .next-slide-button{
  display: none;
}



@media (min-width: 992px) {
  .screen-slider-wrapper {
    position: inherit;
  }
  .screen-slider-wrapper .screen-slider-header {
    padding-top: 64px;
    margin-bottom: 40px;
  }
  .screen-slider-wrapper .screen-slider-header h1 {
    text-align: center;
  }

  .screen-slider-wrapper .screen-slider-content .previous-slide-button {
    display: flex;
    z-index: 1;
    left: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

    .screen-slider-wrapper .screen-slider-content .next-slide-button {
    position: absolute;
    display: block;
    z-index: 1;
    top: 50%;
    right: 32px;
    left: unset;
    transform: translateY(-50%);
  }
}
@media (min-width: 1200px) {
  .screen-slider-wrapper .screen-slider-content .previous-slide-button {
    left: 64px;
  }
  .screen-slider-wrapper .screen-slider-content .next-slide-button {
    right: 64px;
  }
}
