:root{
    --booking-module-shadow-1: 0px 68px 27px rgba(0, 0, 0, 0.01),
    0px 38px 23px rgba(0, 0, 0, 0.05), 0px 17px 17px rgba(0, 0, 0, 0.09),
    0px 4px 9px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

    --booking-module-shadow-2: 0px 154px 61px rgba(21, 27, 30, 0.01),
    0px 86px 52px rgba(21, 27, 30, 0.05), 0px 38px 38px rgba(21, 27, 30, 0.09),
    0px 10px 21px rgba(21, 27, 30, 0.1), 0px 0px 0px rgba(21, 27, 30, 0.1);

    --booking-module-shadow-3: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
