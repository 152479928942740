.btn {
  padding: var(--booking-module-btn-default-padding-y)
    var(--booking-module-btn-default-padding-x);
  font-family: var(--booking-module-btn-font-family);
  font-size: var(--booking-module-btn-default-font-size);
  font-weight: var(--booking-module-btn-default-font-weight);
  line-height: var(--booking-module-btn-default-line-height);
  color: var(--booking-module-btn-default-color);
  border-radius: var(--booking-module-btn-default-border-radius);
  border: none;
  background-color: var(--booking-module-btn-default-bg);
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.15s ease-in-out, padding-bottom 0s, padding-top 0s;

}




.btn.btn-disabled{
  color: var(--booking-module-btn-disabled-color);
  background-color: var(--booking-module-btn-disabled-bg);
  cursor: not-allowed;
}
.btn.btn-primary {
  color: var(--booking-module-btn-primary-color);
  background-color: var(--booking-module-btn-primary-bg);

}

.btn.btn-white {
  color: var(--booking-module-btn-white-color);
  background-color: var(--booking-module-btn-white-bg);
  box-shadow:var(--booking-module-shadow-1)
}
.btn.btn-ghost {
  color: var(--booking-module-btn-ghost-color);
  background-color: var(--booking-module-btn-ghost-bg);
}

.btn.btn-circle {
  border-radius: var(--booking-module-btn-circle-border-radius);
  padding: var(--booking-module-btn-circle-padding-y)
    var(--booking-module-btn-circle-padding-x);
  display: flex;
  width:72px;
  height:72px;
  align-items: center;
  justify-content: center;
}



.btn.btn-link {
  padding: 0;
  background-color: transparent;
  color: var(--booking-module-btn-link-color);
  font-weight: var(--booking-module-btn-link-font-weight);
  text-transform: capitalize;
}

.btn.btn-link:hover {
  color: var(--booking-module-btn-link-hover-color);
}

.btn.btn-link:active {
  color: var(--booking-module-btn-link-active-color);
}
