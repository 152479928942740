:root {
  /* TYPOGRAPHY */
  --booking-module-title-font-family: 'GalanoGrotesque', sans-serif !important;
  --booking-module-body-font-family: 'GalanoGrotesque', sans-serif !important;

  --booking-module-root-font-size: 16px;



  /* TITLE */
  --booking-module-title-primary: var(
          --booking-module-typography-color-primary
  );

  /* TEXT */
  --booking-module-text-primary: var(--booking-module-typography-color-primary);


  /* Font size */
  --booking-module-h1-fs: 4.5rem;
  --booking-module-h4-fs: 3rem;
  --booking-module-h5-fs: 2rem;
  --booking-module-h6-fs: 1.25rem;
  --booking-module-body-fs: 1.25rem;
  --booking-module-body2-fs: 1rem;

  /* Line height */
  --booking-module-h1-lh: 5.5rem;
  --booking-module-h4-lh: 3.5rem;
  --booking-module-h5-lh: 2.5rem;
  --booking-module-h6-lh: 1.75rem;
  --booking-module-body-lh: 1.75rem;
  --booking-module-body2-lh: 1rem;
  --booking-module-body3-lh: 1.25rem;

  /* Font weight */
  --booking-module-fw-bold: 700;
  --booking-module-fw-normal: 400;
  --booking-module-fw-medium: 500;

  /* Gutter */
  --booking-module-h1-mb: 64px;
  --booking-module-h5-mb: 8px;
  --booking-module-h6-mb: 8px;

  /* TITLE */
  /* h1 */
  --booking-module-title-h1-font-size: var(--booking-module-h1-fs);
  --booking-module-title-h1-line-height: var(--booking-module-h1-lh);
  --booking-module-title-h1-font-weight: var(--booking-module-fw-bold);
  --booking-module-title-h1-margin-top: 0;
  --booking-module-title-h1-margin-bottom: 0;
  --booking-module-title-h1-font-family: var(
    --booking-module-title-font-family
  );

  /* h5 */
  --booking-module-title-h5-font-size: var(--booking-module-h5-fs);
  --booking-module-title-h5-line-height: var(--booking-module-h5-lh);
  --booking-module-title-h5-font-weight: var(--booking-module-fw-bold);
  --booking-module-title-h5-margin-top: 0;
  --booking-module-title-h5-margin-bottom: 0;
  --booking-module-title-h5-font-family: var(
    --booking-module-title-font-family
  );
  /* h6 */
  --booking-module-title-h6-font-size: var(--booking-module-h6-fs);
  --booking-module-title-h6-line-height: var(--booking-module-h6-lh);
  --booking-module-title-h6-font-weight: var(--booking-module-fw-bold);
  --booking-module-title-h6-margin-top: 0;
  --booking-module-title-h6-margin-bottom: 0;
  --booking-module-title-h6-font-family: var(
    --booking-module-title-font-family
  );

  /* TEXT */
  /* Body */
  --booking-module-text-body-font-size: var(--booking-module-body-fs);
  --booking-module-text-body-line-height: var(--booking-module-body-lh);
  --booking-module-text-body-font-weight: var(--booking-module-fw-normal);
  --booking-module-text-body-margin-top: 0;
  --booking-module-text-body-margin-bottom: 0;
  --booking-module-text-body-letter-spacing: -0.5px;
  --booking-module-text-body-font-family: var(
    --booking-module-body-font-family
  );
  /* Body2 */
  --booking-module-text-body2-font-size: var(--booking-module-body2-fs);
  --booking-module-text-body2-line-height: var(--booking-module-body2-lh);
  --booking-module-text-body2-font-weight: var(--booking-module-fw-normal);
  --booking-module-text-body2-margin-top: 0;
  --booking-module-text-body2-margin-bottom: 0;
  --booking-module-text-body2-letter-spacing: -0.5px;
  --booking-module-text-body2-font-family: var(
    --booking-module-body-font-family
  );
}
@media screen and (max-width: 992px) {
  :root {
    /* Font size */
    --booking-module-h1-fs: 2.5rem;
    --booking-module-h4-fs: 1.5rem;
    --booking-module-h5-fs: 1.25rem;
    --booking-module-h6-fs: 1rem;
    --booking-module-body-fs: 1rem;

    /* Line height */
    --booking-module-h1-lh: 3rem;
    --booking-module-h4-lh: 2rem;
    --booking-module-h5-lh: 1.75rem;
    --booking-module-h6-lh: 1.5rem;
    --booking-module-body-lh: 1.5rem;

    /* Gutter */
    --booking-module-h1-mb: 0px;
    --booking-module-h5-mb: 8px;
    --booking-module-h6-mb: 4px;
  }
}
