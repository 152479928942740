:root{
    /* BACKGROUND COLOR */
    --booking-module-uploader-bg: var(--booking-module-neutral-lighten-t7);

    /* BORDER */
    --booking-module-uploader-border: var(--booking-module-bw-md) dashed var(--booking-module-neutral-lighten-t5);

    /* SIZE */
    --booking-module-uploader-size: 400px;
    --booking-module-uploader-logo-size: 100px;
}
@media screen and (max-width: 992px) {
  :root {
    /* SIZE */
    --booking-module-uploader-size: 280px;
    --booking-module-uploader-logo-size: 70px;
  }
}
