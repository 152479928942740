:root{
    --booking-module-textarea-gap: 8px;
    --booking-module-textarea-height: 500px;
    --booking-module-textarea-padding: 28px 48px;
    --booking-module-textarea-border-radius: var(--booking-module-br-xxl);
    --booking-module-textarea-bg-color: var(--booking-module-neutral-lighten-t6);
    --booking-module-textarea-fs: var(--booking-module-body-fs);
    --booking-module-textarea-lh: var(--booking-module-body-lh);
    --booking-module-textarea-font-family: var(--booking-module-body-font-family);
}
@media screen and (max-width: 992px)  {
    :root {
        --booking-module-textarea-height: 328px;
        --booking-module-textarea-padding: 20px 32px;
    }
}
