:root {
  /* Accent */
  --booking-module-accent: #2db867;

  /* Accent Transparent */
  --booking-module-accent-lighten-t1: #2db867bf; /* opacity: 0.75 */
  --booking-module-accent-lighten-t2: #2db86780; /* opacity: 0.50 */
  --booking-module-accent-lighten-t3: #2db86740; /* opacity: 0.25 */
  --booking-module-accent-lighten-t4: #2db86726; /* opacity: 0.15 */
  --booking-module-accent-lighten-t5: #2db8671a; /* opacity: 0.10 */
  --booking-module-accent-lighten-t6: #2db8670d; /* opacity: 0.05 */

  /* Neutral (Dark) */
  --booking-module-neutral: #253037;

  /* Neutral Transparent */
  --booking-module-neutral-lighten-t1: #253037bf; /* opacity: 0.75 */
  --booking-module-neutral-lighten-t2: #253037a3; /* opacity: 0.64 */
  --booking-module-neutral-lighten-t3: #25303780; /* opacity: 0.50 */
  --booking-module-neutral-lighten-t4: #25303740; /* opacity: 0.25 */
  --booking-module-neutral-lighten-t5: #25303733; /* opacity: 0.20 */
  --booking-module-neutral-lighten-t6: #2530371a; /* opacity: 0.10 */
  --booking-module-neutral-lighten-t7: #25303714; /* opacity: 0.08 */
  --booking-module-neutral-lighten-t8: #2530370d; /* opacity: 0.05 */

  /* Success */
  --booking-module-success-darken: #15803d;
  --booking-module-success: #22c55e;
  --booking-module-success-lighten: #86efac;

  /* Warning */
  --booking-module-warning-darken: #c2410c;
  --booking-module-warning: #f97316;
  --booking-module-warning-lighten: #fdba74;

  /* Danger */
  --booking-module-danger-darken: #b91c1c;
  --booking-module-danger: #ef4444;
  --booking-module-danger-lighten: #fca5a5;

  /* Gray */
  --booking-module-gray-darken: #3f3f46;
  --booking-module-gray: #808080;
  --booking-module-gray-lighten1: #b3b3b3;
  --booking-module-gray-lighten2: #d9d9d9;
  --booking-module-gray-lighten3: #e6e6e6;

  /* Singletons */
  --booking-module-black: #000000;
  --booking-module-white: #ffffff;

  /* Backgrounds */
  --booking-module-bg-primary: var(--booking-module-white);
  --booking-module-bg-accent: var(--booking-module-accent);
  --booking-module-bg-neutral: var(--booking-module-neutral);
  --booking-module-bg-neutral-lighten-t6: var(
    --booking-module-neutral-lighten-t6
  );

  /* Actions */
  --booking-module-action-primary-default-border: var(
    --booking-module-gray-lighten2
  );
  --booking-module-action-primary-hover-border: var(
    --booking-module-accent-lighten-t1
  );
  --booking-module-action-primary-selected-border: var(--booking-module-accent);
  --booking-module-action-primary-bg: var(--booking-module-black);
  --booking-module-action-primary-hover-bg: var(--booking-module-gray-darken);
  --booking-module-action-primary-color: var(--booking-module-white);
  --booking-module-action-primary-hover-color: var(--booking-module-white);
  --booking-module-action-secondary-color: var(--booking-module-gray-darken);
  --booking-module-action-secondary-default-border: var(
    --booking-module-gray-lighten2
  );
  --booking-module-action-secondary-hover-border: var(--booking-module-black);
  --booking-module-action-secondary-hover-color: var(--booking-module-white);
  --booking-module-action-secondary-hover-bg: var(--booking-module-black);

  /* Typography */
  --booking-module-typography-color-primary: var(--booking-module-neutral);
  --booking-module-typography-color-accent: var(--booking-module-accent);
  --booking-module-typography-color-secondary: var(--booking-module-gray);
  --booking-module-typography-color-white: var(--booking-module-white);
  --booking-module-typography-color-success: var(--booking-module-success);
  --booking-module-typography-color-warning: var(--booking-module-warning);
  --booking-module-typography-color-danger: var(--booking-module-danger);
  --booking-module-typography-color-default: var(--booking-module-neutral);

}
